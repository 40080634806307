var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  size: function size(state) {
    return state.app.size;
  },
  device: function device(state) {
    return state.app.device;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  name: function name(state) {
    return state.user.name;
  },
  introduction: function introduction(state) {
    return state.user.introduction;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  permissions: function permissions(state) {
    return state.user.permissions;
  },
  sidebarRouters: function sidebarRouters(state) {
    return state.permission.sidebarRouters;
  },
  errorLogs: function errorLogs(state) {
    return state.errorLog.logs;
  },
  isLogin: function isLogin(state) {
    return state.user.isLogin;
  },
  adminProductClassify: function adminProductClassify(state) {
    return state.product.adminProductClassify;
  },
  merProductClassify: function merProductClassify(state) {
    return state.product.merProductClassify;
  },
  productBrand: function productBrand(state) {
    return state.product.productBrand;
  },
  merchantClassify: function merchantClassify(state) {
    return state.merchant.merchantClassify;
  },
  merchantType: function merchantType(state) {
    return state.merchant.merchantType;
  },
  shippingTemplates: function shippingTemplates(state) {
    return state.product.shippingTemplates;
  }
};
export default getters;