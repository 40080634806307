function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import request from '@/utils/request';

/**
 * @description 全部物流公司
 */
export function expressAllApi(params) {
  return request({
    url: 'admin/merchant/express/all',
    method: 'get',
    params: params
  });
}

/**
 * @description 物流运费模板列表
 */
export function shippingTemplatesList(data) {
  return request({
    url: '/admin/merchant/shipping/templates/list',
    method: 'get',
    params: _objectSpread({}, data)
  });
}

/**
 * @description 物流运费模板详情
 */
export function templateDetailApi(data) {
  return request({
    url: "/admin/merchant/shipping/templates/info/".concat(data.id),
    method: 'get'
  });
}

/**
 * @description 物流运费模板新增
 */
export function shippingSave(data) {
  return request({
    url: 'admin/merchant/shipping/templates/save',
    method: 'post',
    data: data
  });
}

/**
 * @description 物流运费模板更新
 */
export function shippingUpdate(data) {
  return request({
    url: 'admin/merchant/shipping/templates/update',
    method: 'post',
    data: data
  });
}

/**
 * @description 物流运费模板删除
 */
export function shippingDetete(data) {
  return request({
    url: "admin/merchant/shipping/templates/delete/".concat(data.id),
    method: 'POST'
  });
}

/**
 * @description 城市列表
 */
export function cityListTree() {
  return request({
    url: '/admin/merchant/city/region/city/tree',
    method: 'get'
  });
}

/**
 * @description 查询物流公司面单模板
 */
export function exportTempApi(params) {
  return request({
    url: '/admin/merchant/express/template',
    method: 'get',
    params: params
  });
}