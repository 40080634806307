import request from '@/utils/request';
export function login(data) {
  return request({
    url: '/admin/supplier/login',
    method: 'post',
    data: data
  });
}
export function getInfo(token) {
  return request({
    url: '/admin/supplier/getAdminInfoByToken',
    method: 'get',
    params: {
      token: token
    }
  });
}
export function logout() {
  return request({
    url: '/admin/merchant/logout',
    method: 'get'
  });
}

/**
 * 会员管理 列表
 * @param pram
 */
export function userListApi(params) {
  return request({
    url: "/admin/merchant/user/list",
    method: 'get',
    params: params
  });
}

/**
 *获取登录页图片
 */
export function getLoginPicApi() {
  return request({
    url: "/admin/supplier/getLoginPic",
    method: 'get'
  });
}

/**
 * @description 验证码
 */
export function captchaApi() {
  return request({
    url: "/publicly/validate/code/get",
    method: 'get'
  });
}

/**
 * @description 用户详情列表
 */
export function userDetailApi(id) {
  return request({
    url: "/admin/merchant/user/detail/".concat(id),
    method: 'get'
  });
}