import request from '@/utils/request';

/**
 * 菜单
 * @param pram
 */
export function menuListApi() {
  return request({
    url: '/admin/supplier/getMenus',
    method: 'GET'
  });
}